@use '@ctrl/ngx-emoji-mart/picker';
@use "site-colors" as *;
@use "controls";
@use "typography";
@use '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600&display=swap');

html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  background: url("./assets/images/Bg/pink-circle.png") no-repeat fixed var(--bg-color);
  background-size: cover;
  font-size: 17px;
  //font-family: "Plus Jakarta Sans", sans-serif;
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  height: 100vh;
  margin: 0;
  @media (max-width: 767px) {
    background-position: right;
  }
}

body.dark {
  @include theme-colors($dark-background-color, $dark-mode-surface-color, $shadow-dark, $highlight-color-dark, $highlight-color-dark, $dark-mode-font-color, $dark-mode-font-color-muted, dark);
}

body.light {
  @include theme-colors($background-color, $surface-color, $shadow-light, $highlight-color, $highlight-color, $font-color, $font-color-muted);
}

.root {
  display: flex;
  flex-direction: column;
}

.emoji-mart-search,
.emoji-mart-preview {
  display: none !important;
}

.d-flex {
  display: flex;
}

.rounded-circle {
  border-radius: 50% !important;
}

//!!DEBUG: Overflow detection
//* {
//  border: 1px solid #f00 !important;
//  margin: 0;
//  padding: 0;
//}

*, *:before, *:after {
  box-sizing: border-box;
}

.popup {
  padding: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0.125rem 0.5rem var(--shadow);
  background-color: var(--surface-color);
  min-width: 50px;
  max-width: 300px;
  flex-flow: column;
}
