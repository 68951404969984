@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

$font-size-sm: 15px;
$font-size-md: 17px;
$font-size-lg: 19px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

@font-face {
  font-family: EDB Wild Things;
  src: url("/assets/fonts/edbwt.ttf") format("truetype");
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1, p, span, small {
  margin: 0;
  color: var(--font-color);
}

.text-muted {
  color: var(--font-color-muted);
}

h1, .h1 {
  font-size: 32px;
  font-weight: 900;
}

h2, .h2 {
  font-size: 28px;
  font-weight: 800;
}

h3, .h3 {
  font-size: 24px;
  font-weight: 700;
}

h4, .h4 {
  font-size: $font-size-lg;
  font-weight: 700;
}

h5, .h5 {
  font-size: $font-size-md;
  font-weight: 600;
}

h6, .h6 {
  font-size: $font-size-sm;
  font-weight: 500;
}

p, span {
  font-size: $font-size-md;
  font-weight: 350;
  color: var(--font-color);
}

small {
  font-size: $font-size-sm;
  font-weight: 300;
  color: var(--font-color);
}

.text-large {
  font-size: 1.1em;
  font-weight: 700;
}

.text-xlarge {
  font-size: 1.2em;
  font-weight: 800;
}

.text-xxlarge, .h1, h1 {
  font-size: 1.5em;
  font-weight: 900;
}
