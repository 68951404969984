@use "site-colors";

.btn {
  border-radius: 20px;
  padding: 0.375rem 0.75rem;
  color: #0d6efd;
  border: 1px solid #0d6efd;
  background-color: transparent;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    color: #ffffff;
    background-color: #0d6efd;
  }
}

.btn, button, input[button] {
  &:hover {
    cursor: pointer;
  }
}

.btn.btn-block {
  margin-top: 20px;
  color: #576daf;

  &:hover {
    color: #fff;
    cursor: pointer;
  }
}

.btn-accent {
  color: site-colors.$accent-color;
}

input, select, .form-control, .form-check-group {
  box-shadow: none;
  border: none;
  border-radius: 1em;
  outline: none !important;
  background-color: var(--control-bg);
  line-height: 2.5em;
  width: 100%;
  padding: 10px 15px;
  font-size: large;
  height: 2.5em;
}

input[type="date"] {
  height: 3em;
  border-radius: 1.2em;
  color-scheme: var(--color-scheme);
}

button {
  height: 2.2em;
}

.form-check-label:hover {
  color: grey;
}

.form-check {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  * {
    cursor: pointer;
    width: fit-content;

    &[type=checkbox],
    &[type=radio] {
      width: 1.2em;
      margin-top: 2px;
    }
  }
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif;
  background: var(--control-bg);

  &:focus {
    background: var(--control-focus);
  }
}

@media (min-width: 768px) {
  .btn {
    border-radius: 16px;
  }
}
