$accent-color: #0d6efd;

$background-color: #e9eaed;
$dark-background-color: #1a222c;

$surface-color: white;
$dark-mode-surface-color: #12191e;

$highlight-color: #f4f7fc;
$highlight-color-dark: #293544;

$shadow-light: #ededed;
$shadow-dark: #232323;

$font-color: #52546D;
$dark-mode-font-color: #eaeaf3;

$font-color-muted: #a5aaba;
$dark-mode-font-color-muted: #6b7373;

$link-color-muted: #5C5C5C;
$primary-color: #f9fbfc;

@mixin theme-colors(
  $bg-color,
  $surface-color,
  $shadow,
  $highlight,
  $control,
  $font-color,
  $font-color-muted,
  $color-scheme: light
) {
  --bg-color: #{$bg-color};
  --surface-color: #{$surface-color};
  --shadow: #{$shadow};
  --highlight: #{$highlight};
  --control-bg: #{$control};
  --control-focus: #{$highlight};
  --color-scheme: #{$color-scheme};
  --font-color: #{$font-color};
  --font-color-muted: #{$font-color-muted};
}
